import * as React from 'react'
import { ALERT_VARIANT_OPTIONS } from './enums'
import clsx from 'clsx'
import parseHTMLToJSX from '@/utils/parse-html-to-jsx'

export default function Infobox(props) {
  const {
    variant = ALERT_VARIANT_OPTIONS.INFO,
    children,
    fill,
    title,
    hideMessage,
    showAction,
    labelBtn,
    showIcon = false,
    showClose = false,
    onClickAction,
    onClose,
  } = props

  const CONTAINER_ALERT = 'si-alert'

  const icon = () => {
    switch (variant) {
      case ALERT_VARIANT_OPTIONS.INFO:
        return 'fa-info-circle'
      case ALERT_VARIANT_OPTIONS.SUCCESS:
        return 'fa-check-circle'
      default:
        return 'fa-exclamation-triangle'
    }
  }

  return (
    <>
      <div
        className={clsx(
          `${CONTAINER_ALERT}`,
          fill
            ? `${CONTAINER_ALERT}--${variant}-fill`
            : `${CONTAINER_ALERT}--${variant}`,
        )}
      >
        <div
          className={clsx(
            'si-text',
            'si-text__body--small',
            `${CONTAINER_ALERT}__body--${variant}`,
            fill ? `${CONTAINER_ALERT}__body--fill` : '',
          )}
        >
          {showIcon ? (
            <div className={clsx(`${CONTAINER_ALERT}__icon`)}>
              <i
                className={clsx(
                  `${CONTAINER_ALERT}__icon--${variant}`,
                  fill ? `${CONTAINER_ALERT}__icon--fill` : '',
                  `fa`,
                  `${icon()}`,
                )}
              ></i>
            </div>
          ) : (
            ``
          )}
          <div className={`${CONTAINER_ALERT}__container--content`}>
            {!title ? (
              ``
            ) : (
              <span
                className={clsx('si-text--bold')}
                style={{ textTransform: 'uppercase' }}
              >
                {title}
              </span>
            )}
            {hideMessage ? '' : <span>{parseHTMLToJSX(children)}</span>}
          </div>
        </div>
        {(showAction || showClose) && (
          <div className={clsx(`${CONTAINER_ALERT}__container--btn`)}>
            <button
              data-type="action"
              onClick={onClickAction}
              className={
                showAction
                  ? `${CONTAINER_ALERT}__btn-action`
                  : `${CONTAINER_ALERT}--hide`
              }
            >
              <label className="si-text si-text__body--small si-text--bold">
                {labelBtn}
              </label>
            </button>
            <i
              data-dismiss="alert"
              className={clsx(
                showClose
                  ? `${CONTAINER_ALERT}__btn-close`
                  : `${CONTAINER_ALERT}--hide`,
              )}
              onClick={onClose}
            >
              &times;
            </i>
          </div>
        )}
      </div>
    </>
  )
}
